import React from 'react';
import SEO from '../../components/seo';
import ArtsIntroductionEN from '../../components/desktop/ArtsIntroduction/ArtsIntroductionEN';

const Arts = () => {
  return (
    <>
      <SEO title={'Arts'} />
      <ArtsIntroductionEN />
    </>
  );
};

export default Arts;
